<template>
	<modal class="NoxModalBonusSettings" name="NoxModalBonusSettings" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'restartBonus'">Перезапуск бонуса</span>
					<span v-else-if="noxType == 'sendBonusAlert'">Отправка рассылки</span>
					<span v-else-if="noxType == 'editBonusSettings'">Редактирование настроек бонуса</span>
				</div>
			</div>
			<div class="nox_modal_body">
				<div class="nox_modal_info">
					<div v-if="noxType == 'restartBonus'">
						<p>Вы действительно хотите перезапустить "<b>{{ (noxBonus == 4 ? 'БУСТЕР БОНУС' : (noxBonus == 6 ? 'ПОДАРКИ' : 'PERK БОНУС')) }}</b>"?</p>
						<div v-if="noxBonus == 6">
							<p>Выберите тип перезапуска:</p>
							<p class="nox_p_radios">
								<label class="nox_radio" :for="'nox_radio_bonus_type_' + index" v-for="(value, index) in 4" :key="index">
									<input type="radio" :id="'nox_radio_bonus_type_' + index" name="nox_radio_bonus_type" :value="value" v-model.number="noxBonusType">
									<span class="nox_radio_mark"></span>
									<span class="nox_radio_text">
										<span v-if="value == 1">перезапуск бонуса (рекомендуется выбирать в тех случаях, когда администратор включает бонус для любой из групп пользователей и при этом таймер отсчета количества дней либо истек, либо был никогда не запущен)</span>
										<span v-else-if="value == 2">полный перезапуск бонуса для всех пользователей</span>
										<span v-else-if="value == 3">полное обнуление количества дней для всех пользователей</span>
										<span v-else-if="value == 4">полное обнуление количества циклов для всех пользователей</span>
									</span>
								</label>
							</p>
						</div>
					</div>
					<div v-else-if="noxType == 'sendBonusAlert'">
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Группа рассылки:</div>
							<div class="nox_modal_info_row_value">
								<v-select class="nox_select style-chooser" v-model.trim="noxBonusGroup" placeholder="выберите группу рассылки" :options="noxBonusGroups"></v-select>
								<div v-html="noxAlertBonusGroup"></div>
							</div>
						</div>
						<div class="nox_modal_info_row last">
							<div class="nox_modal_info_row_label">Тип рассылки:</div>
							<div class="nox_modal_info_row_value">
								<v-select class="nox_select style-chooser" v-model.trim="noxBonusAction" placeholder="выберите тип рассылки" :options="noxBonusActions"></v-select>
								<div v-html="noxAlertBonusAction"></div>
							</div>
						</div>
					</div>
					<div v-else-if="noxType == 'editBonusSettings'">
						<div class="nox_modal_info_row last">
							<div class="nox_modal_info_row_label">Список стран:</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxBonusCountries" placeholder="список стран" autocomplete="off">
								<div v-html="noxAlertBonusCountries"></div>
							</div>
						</div>
					</div>
					<div v-html="noxAlertBonus"></div>
				</div>
				<div class="nox_modal_alert" v-html="noxAlert"></div>
				<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
					<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-if="noxType == 'restartBonus'" v-html="$store.getters.getLanguageText('1.1', 69)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-else-if="noxType == 'sendBonusAlert'" v-html="$store.getters.getLanguageText('1.1', 45)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-else-if="noxType == 'editBonusSettings'" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertBonus: '',
			noxAlertBonusGroup: '',
			noxAlertBonusAction: '',
			noxAlertBonusCountries: '',
			noxType: '',
			noxBonus: 0,
			noxBonusType: 0,
			noxBonusGroup: '',
			noxBonusGroups: [],
			noxBonusAction: '',
			noxBonusActions: [],
			noxBonusSettings: {},
			noxBonusCountries: '',
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxType = event.params.type;
				this.noxBonus = event.params.bonus;
				this.noxBonusType = 0;
				this.noxBonusGroup = '';
				this.noxBonusGroups = [];
				this.noxBonusAction = '';
				this.noxBonusActions = [];
				this.noxBonusSettings = {};
				this.noxBonusCountries = '';
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (this.noxType == 'sendBonusAlert') {
					for (var i in this.$parent.noxSystemGroups) {
						this.noxBonusGroups.push({ code: this.$parent.noxSystemGroups[i].group, label: this.$parent.noxSystemGroups[i]['name_' + this.$store.state.noxLanguage] });
					}
					this.noxBonusActions = [
						{ code: 0, label: 'включение бонуса' },
						{ code: 1, label: 'отключение бонуса' }
					];
				}
				else if (this.noxType == 'editBonusSettings') {
					this.noxBonusCountries = this.$parent.noxSystemSettings['bonus_' + this.noxBonus + '_countries'];
				}
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertBonus = '';
				this.noxAlertBonusGroup = '';
				this.noxAlertBonusAction = '';
				this.noxAlertBonusCountries = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо указать id бонуса.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо указать верный id бонуса.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо указать тип перезапуска бонуса.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо указать верный тип перезапуска бонуса.'; }
				else if (i == 5) { this.noxTemp = 'Необходимо выбрать группу рассылки.'; }
				else if (i == 6) { this.noxTemp = 'Необходимо выбрать верную группу рассылки.'; }
				else if (i == 7) { this.noxTemp = 'Необходимо выбрать тип рассылки.'; }
				else if (i == 8) { this.noxTemp = 'Необходимо выбрать верный тип рассылки.'; }
				else if (i == 9) { this.noxTemp = 'Необходимо указать настройки бонуса.'; }
				else if (i == 10) { this.noxTemp = 'Необходимо указать верные настройки бонуса.'; }
				else if (i == 11) { this.noxTemp = 'Необходимо указать список стран бонуса.'; }
				else if (i == 12) { this.noxTemp = 'Необходимо указать верный список стран бонуса.'; }
				else              { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (_this.noxType == 'restartBonus') {
						if (_this.noxBonus == 6) {
							if (!_this.noxBonusType) {
								_this.noxAlertBonus = _this.getError(3);
							}
							else if (!_this.$store.state.noxRegex_d.test(_this.noxBonusType) || ![1,2,3,4].includes(_this.noxBonusType)) {
								_this.noxAlertBonus = _this.getError(4);
							}
							if (!_this.noxIsError) {
								_this.noxBonus = (Number(_this.noxBonusType) + 60) / 10;
							}
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/bonus/restart';
							config.data = { id: _this.noxBonus };
							config.method = 'patch';
						}
					}
					else if (_this.noxType == 'sendBonusAlert') {
						if (!_this.noxBonusGroup) {
							_this.noxAlertBonusGroup = _this.getError(5);
						}
						if (!_this.noxBonusAction) {
							_this.noxAlertBonusAction = _this.getError(7);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/bonus/alerts';
							config.data = { id: _this.noxBonus, group: _this.noxBonusGroup.code, action: _this.noxBonusAction.code };
							config.method = 'patch';
						}
					}
					else if (_this.noxType == 'editBonusSettings') {
						if (_this.noxBonusCountries) {
							var countries = String(_this.noxBonusCountries).split(',');
							if (countries.length) {
								for (var i in countries) {
									if (!_this.$store.state.noxRegexCountry.test(countries[i])) {
										_this.noxAlertBonusCountries = _this.getError(12); break;
									}
								}
							}
						}
						if (!_this.noxIsError) {
							_this.noxBonusSettings['bonus_' + _this.noxBonus + '_countries'] = _this.noxBonusCountries;
							config.url = '/v2/admin/account/bonus/settings';
							config.data = { id: _this.noxBonus, settings: _this.noxBonusSettings };
							config.method = 'patch';
						}
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (['restartBonus', 'sendBonusAlert', 'editBonusSettings'].includes(_this.noxType)) {
									if (_this.noxType == 'editBonusSettings') {
										_this.$parent.noxSystemSettings['bonus_' + _this.noxBonus + '_countries'] = _this.noxBonusCountries;
									}
									_this.close();
								}
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (_this.noxType == 'restartBonus') {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
									}
									else if (_this.noxType == 'sendBonusAlert') {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'GROUP_IS_EMPTY') { _this.noxAlertBonusGroup = _this.getError(5); }
										else if (data.response.data.error == 'GROUP_NOT_VALID') { _this.noxAlertBonusGroup = _this.getError(6); }
										else if (data.response.data.error == 'ACTION_IS_EMPTY') { _this.noxAlertBonusAction = _this.getError(7); }
										else if (data.response.data.error == 'ACTION_NOT_VALID') { _this.noxAlertBonusAction = _this.getError(8); }
									}
									else if (_this.noxType == 'editBonusSettings') {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'SETTINGS_IS_EMPTY') { _this.noxAlert = _this.getError(9); }
										else if (data.response.data.error == 'SETTINGS_NOT_VALID') { _this.noxAlert = _this.getError(10); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('NoxModalBonusSettings');
			}
		}
	}
</script>
